import request from '@utils/request_gd'

/*
* 冲刺统计
* */
export const getJsrTotal = (params = {}) => {
  return request({
    url: '/api/v1/chongCiJsr/getTotal',
    method: 'get',
    params
  })
}
/*
* 当前日冲刺
* */
export const getJsrTodayChongCiList = (params = {}) => {
  return request({
    url: '/api/v1/chongCiJsr/getTodayChongCiList',
    method: 'get',
    params
  })
}
/*
* 冲刺明细列表
* */
export const getJsrDetailList = (params = {}) => {
  return request({
    url: '/api/v1/chongCiJsr/getList',
    method: 'get',
    params
  })
}

/*
* 区域列表
* */
export const getJsrAreaChongCiList = (params = {}) => {
  return request({
    url: '/api/v1/chongCiJsr/getAreaChongCiList',
    method: 'get',
    params
  })
}
/*
* 区域列表明细
* */
export const getJsrAreaChongCiDetail = (params = {}) => {
  return request({
    url: '/api/v1/chongCiJsr/getAreaChongCiDetailList',
    method: 'get',
    params
  })
}
