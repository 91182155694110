<template>
  <div class='container'>
    <div class='select'>
      <span>统计时间</span>
      <span class='start_date' @click='startDateInfo.show=true'>
        <van-image
          class='img_icon'
          width='20'
          height='20'
          :src="require('../../assets/images/date.png')"
        />
        <span style='margin-left: 10px' class='date'>{{ startDate }}</span>
      </span>
      <span>至</span>
      <span class='end_date' @click='endDateInfo.show=true'>
        <van-image
          class='img_icon'
          width='20'
          height='20'
          :src="require('../../assets/images/date.png')"
        />
        <span style='margin-left: 10px' class='date'>{{ endDate }}</span>
      </span>
    </div>

    <div class='Condition'>
      <div v-for='(item,idx) in conditionList'
           :class="conditionCheckName===item?'button-green':'button-red'"
           :key='idx'>
        <van-button type='default' size='small' @click='handleCondition(item)'>
          {{ item }}
        </van-button>
      </div>
    </div>
    <van-tabs v-if='showType === 1 || showType === 2 ' v-model='statisticsType' style='background-color: #F8F8F8;'
              @change='handleChange'>
      <van-tab name='1' title='总营收'></van-tab>
      <van-tab name='2' title='净收入'></van-tab>
    </van-tabs>
    <div class='content'>
      <div class='content-table'>
        <!-- 缺省 -->
        <no-data
          :msg='noDataMessage'
          v-if='showType === 0 && isLoading === false'
        >
          <template #btn>
            <div
              class='cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius'
              @click='initData'
            >
              刷新重试
            </div>
          </template>
        </no-data>

        <!-- 当前日冲刺 -->
        <table v-if='showType===1' class='spec_table table'>
          <thead>
          <th>项目名称</th>
          <th>昨日滚动目标</th>
          <th>昨日完成</th>
          <th>昨日完成进度</th>
          <th>差额</th>
          <th>今日滚动目标</th>
          <th>同期营收</th>
          <th>上个周期</th>
          </thead>
          <tbody>
          <tr v-for='item in tableList' :key='item.id'>
            <td :class='projectType(item)' @click='handleProject(item)'>{{ item.projectName }}</td>
            <td>
              <span v-if='isMinus(item.targetVal)' style='color: red;'>已完成本月目标</span>
              <span v-else>{{ getThousandsValue(item.targetVal) }}</span>
            </td>
            <td>{{ getThousandsValue(item.completeVal) }}</td>
            <td :class='completeType(item)'>
              <span v-if='isMinus(item.targetVal)' style='color: red;'>已完成本月目标</span>
              <span v-else>{{ item.completeProgress }} %</span>
            </td>
            <td :class='completeType(item)'>{{ disposeLeftVal(item) }}</td>
            <td>
              <span v-if='isMinus(item.todayTargetDay)' style='color: red;'>已完成本月目标</span>
              <span v-else>{{ getThousandsValue(item.todayTargetDay) }}</span>
            </td>
            <td>{{ getThousandsValue(item.completeValLastYear) }}</td>
            <td>{{ getThousandsValue(item.completeValLastDay) }}</td>
          </tr>
          </tbody>
        </table>

        <!-- 不同日冲刺 -->
        <table v-if='showType===2' class='spec_table table'>
          <thead>
          <th>项目名称</th>
          <th>预算目标</th>
          <th>完成</th>
          <th>进度</th>
          <th>差额</th>
          <th>同期营收</th>
          <th>上个周期</th>
          </thead>
          <tbody>
          <tr v-for='item in tableList' :key='item.id'>
            <td :class='projectType(item)' @click='handleProject(item)'>{{ item.projectName }}</td>
            <td>{{ getThousandsValue(item.targetVal) }}</td>
            <td>{{ getThousandsValue(item.completeVal) }}</td>
            <td :class='completeType(item)'>{{ item.completeProgress }} %</td>
            <td :class='completeType(item)'>{{ disposeLeftVal(item) }}</td>
            <td>{{ getThousandsValue(item.completeValLastYear) }}</td>
            <td>{{ getThousandsValue(item.completeValLastDay) }}</td>
          </tr>
          </tbody>
        </table>

        <!-- 明细列表 -->
        <van-button v-if='showType===3' style='margin-bottom: 5px'
                    @click='handleBack'
                    icon='arrow-left' type='default' size='mini'>
          返回
        </van-button>
        <table v-if='showType===3' class='spec_table table'>
          <thead>
          <th>项目名称</th>
          <th>日期</th>
          <th>预算目标</th>
          <th>完成</th>
          <th>进度</th>
          <th>差额</th>
          <th>同期营收</th>
          <th>上个周期</th>
          </thead>
          <tbody>
          <tr v-for='item in detailList' :key='item.id'>
            <td :class='projectDetailType(item)'>{{ item.projectName }}</td>
            <td>{{ item.day }}</td>
            <td>{{ getThousandsValue(item.targetVal) }}</td>
            <td>{{ getThousandsValue(item.completeVal) }}</td>
            <td :class='completeType(item)'>{{ item.completeProgress }} %</td>
            <td :class='completeType(item)'>{{ disposeLeftVal(item) }}</td>
            <td>{{ getThousandsValue(item.completeValLastYear) }}</td>
            <td>{{ getThousandsValue(item.completeValLastDay) }}</td>
          </tr>
          </tbody>
        </table>

        <!-- 片区统计 -->
        <van-button v-if='showType===4' style='margin-bottom: 5px'
                    @click='handleBack'
                    icon='arrow-left' type='default' size='mini'>
          返回
        </van-button>
        <table v-if='showType===4' class='spec_table table'>
          <thead>
          <th>片区名称</th>
          <th>预算目标</th>
          <th>完成</th>
          <th>进度</th>
          <th>差额</th>
          <th>同期营收</th>
          <th>上个周期</th>
          </thead>
          <tbody>
          <tr v-for='item in areaList' :key='item.id'>
            <td :class='projectType(item)' @click='handleAreaProject(item)'>{{ item.projectName }}</td>
            <td>{{ getThousandsValue(item.targetVal) }}</td>
            <td>{{ getThousandsValue(item.completeVal) }}</td>
            <td :class='completeType(item)'>{{ item.completeProgress }} %</td>
            <td :class='completeType(item)'>{{ disposeLeftVal(item) }}</td>
            <td>{{ getThousandsValue(item.completeValLastYear) }}</td>
            <td>{{ getThousandsValue(item.completeValLastDay) }}</td>
          </tr>
          </tbody>
        </table>

        <!-- 片区统计明细列表 -->
        <van-button v-if='showType===5' style='margin-bottom: 5px'
                    @click='handleAreaBack'
                    icon='arrow-left' type='default' size='mini'>
          返回
        </van-button>
        <table v-if='showType===5' class='spec_table table'>
          <thead>
          <th>项目名称</th>
          <th>日期</th>
          <th>预算目标</th>
          <th>完成</th>
          <th>进度</th>
          <th>差额</th>
          <th>同期营收</th>
          <th>上个周期</th>
          </thead>
          <tbody>
          <tr v-for='item in areaDetailList' :key='item.id'>
            <td :class='projectDetailType(item)'>{{ item.projectName }}</td>
            <td>{{ item.day }}</td>
            <td>{{ getThousandsValue(item.targetVal) }}</td>
            <td>{{ getThousandsValue(item.completeVal) }}</td>
            <td :class='completeType(item)'>{{ item.completeProgress }} %</td>
            <td :class='completeType(item)'>{{ disposeLeftVal(item) }}</td>
            <td>{{ getThousandsValue(item.completeValLastYear) }}</td>
            <td>{{ getThousandsValue(item.completeValLastDay) }}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div v-if='showType!=4' class='area' @click='handleArea'>
      片区视角
    </div>
    <van-calendar ref='rentalDateCalendar'
                  v-model='startDateInfo.show'
                  :default-date='startDateInfo.defaultDate'
                  :get-container='getContainer'
                  :max-date='startDateInfo.maxDate'
                  :min-date='startDateInfo.minDate'
                  color='#D1A36F'
                  type='single'
                  @confirm='handleStartCalendar' />
    <van-calendar ref='rentalDateCalendar'
                  v-model='endDateInfo.show'
                  :default-date='endDateInfo.defaultDate'
                  :get-container='getContainer'
                  :max-date='endDateInfo.maxDate'
                  :min-date='endDateInfo.minDate'
                  color='#D1A36F'
                  type='single'
                  @confirm='handleEndCalendar' />
  </div>
</template>

<script>
  import moment from 'moment'
  import {
    authorizedViewing,
    getAreaChongCiDetail,
    getAreaChongCiList,
    getDetailList,
    getTodayChongCiList,
    getTotal
  } from '@api/chongci'
  import NoData from '@components/NoData/NoData.vue'
  import { getThousandsValue } from '@utils/utils'
  import {
    getJsrAreaChongCiDetail,
    getJsrAreaChongCiList,
    getJsrDetailList,
    getJsrTodayChongCiList,
    getJsrTotal
  } from '@api/chongci_income'
  import { getStorage, setStorage } from '@utils/storage'
  import WaterMark from '@utils/waterMark'

  export default {
    name: 'ChongciIndex',
    components: { NoData },
    props: {},
    data() {
      return {
        pmsUserId: '',
        pmsUserName: '',
        code: '',
        state: '',
        noDataMessage: '暂无冲刺统计',
        isLoading: false,
        projectId: '', // 查看详情时的projectId
        defaultDate: '', // 限制时间
        /*
          showType
        * 0不显示 为缺省页
        * 1为当前日冲刺
        * 2为不同日冲刺
        * 3为明细页
        * 4为片区视角页
        * 5为片区明细列表
        */
        showType: 0,
        startDate: '',
        endDate: '',
        dateType: '',
        tableList: [],
        detailList: [],
        areaList: [],
        areaDetailList: [],
        conditionList: ['昨日', '今日', '上周', '本周', '上月', '本月'],
        conditionCheckName: '', // 选中时按钮名称
        statisticsType: '1',
        startDateInfo: {
          show: false,
          date: [],
          minDate: new Date('2023/09/01'),
          maxDate: new Date('2024/12/31'),
          defaultDate: new Date()
        },
        endDateInfo: {
          show: false,
          date: [],
          minDate: new Date('2023/09/01'),
          maxDate: new Date('2024/12/31'),
          defaultDate: new Date()
        }
      }
    },
    computed: {
      completeType: function() {
        return function(item) {
          if (Number(item.targetVal) < 0) {
            return 'red'
          }
          if (Number(item.completeProgress) >= 100) {
            return 'red'
          } else if (Number(item.completeProgress) < 100) {
            return 'green'
          }
        }
      },
      projectType: function() {
        return function(item) {
          if (item.projectName === '合计') {
            return 'red'
          } else {
            return 'blue'
          }
        }
      },
      projectDetailType: function() {
        return function(item) {
          if (item.projectName === '合计') {
            return 'red'
          } else {
            return ''
          }
        }
      }
    },
    watch: {},
    async created() {
      // let date = this.$route.query.date
      // if (date) {
      //   this.defaultDate = moment(date).format('YYYY-MM-DD')
      //   this.startDate = moment(date).format('YYYY-MM-DD')
      //   this.endDate = moment(date).format('YYYY-MM-DD')
      //   this.dateType = 'day'
      //   await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
      // }
    },
    async mounted() {
      await this.getParams()
      //  测试
      await this.setDateRange()
      // 正式
      // await this.getPmsUserId()
    },
    methods: {
      getParams() {
        let url = window.location.href
        let obj = {}
        let index = url.indexOf('?') // 看url有没有参数
        let params = url.substr(index + 1) // 截取url参数部分 name = aaa & age = 20
        if (index !== -1) { // 有参数时
          let parr = params.split('&') // 将参数分割成数组 ["name = aaa", "age = 20"]
          for (let i of parr) { // 遍历数组
            let arr = i.split('=')
            obj[arr[0]] = arr[1]
          }
        }
        this.code = obj.code
        this.state = obj.state
        this.pmsUserId = obj.pmsUserId
        this.pmsUserName = 'admin'
        if (obj.date) {
          this.defaultDate = moment(obj.date).format('YYYY-MM-DD')
          this.startDate = moment(obj.date).format('YYYY-MM-DD')
          this.endDate = moment(obj.date).format('YYYY-MM-DD')
          this.dateType = 'day'
        }
      },
      async getPmsUserId() {
        if (this.code) {
          let pmsUserId = getStorage('pmsUserId')
          let pmsUserName = getStorage('pmsUserName')
          if (pmsUserId) {
            this.pmsUserId = pmsUserId
            this.pmsUserName = pmsUserName
            await this.setDateRange()
          } else {
            let {
              code,
              state
            } = this
            const response = await authorizedViewing({
              code,
              state
            })
            let result = response.data
            if (result.code === 200) {
              this.pmsUserId = result.data.pmsUserId
              this.pmsUserName = result.data.pmsUserName
              setStorage('pmsUserId', this.pmsUserId)
              setStorage('pmsUserName', this.pmsUserName)
              await this.setDateRange()
            }
          }
        } else { // 开发状态下 自定义参数配置
          this.pmsUserId = 'ZhouHongZhao'
          this.pmsUserName = '钱永超'
          setStorage('pmsUserId', this.pmsUserId)
          setStorage('pmsUserName', this.pmsUserName)
          await this.setDateRange()
        }
        await this.warter_mark(this.pmsUserName)
      },
      getThousandsValue,
      isMinus(val) {
        let sign = Math.sign(val)
        if (sign === -1) {
          return true
        } else {
          return false
        }
      },
      // 返回一个特定的 DOM 节点，作为挂载的父节点
      getContainer() {
        return document.querySelector('body')
      },
      async initData() {
        await this.getTableList()
      },
      handleChange(val) {
        this.initData()
      },
      async getTableList({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          // 判断是否等于url给定时间
          let response
          if ((this.startDate == this.defaultDate) && (this.endDate == this.defaultDate)) {
            this.showType = 1
            let day = moment(this.defaultDate).format('YYYYMMDD')
            response = this.statisticsType === '1'
              ? await getTodayChongCiList({ day, dateType: this.dateType, pmsUserId: this.pmsUserId })
              : await getJsrTodayChongCiList({ day, dateType: this.dateType, pmsUserId: this.pmsUserId })
          } else {
            this.showType = 2
            let startDate = moment(this.startDate).format('YYYYMMDD')
            let endDate = moment(this.endDate).format('YYYYMMDD')
            response = this.statisticsType === '1'
              ? await getTotal({ startDate, endDate, dateType: this.dateType, pmsUserId: this.pmsUserId })
              : await getJsrTotal({ startDate, endDate, dateType: this.dateType, pmsUserId: this.pmsUserId })
          }
          if (`${response.data.code}` == 200) { // 请求成功
            console.log(this.showType)
            this.tableList = response.data.result || []
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: response.msg || '获取失败!'
            })
            return Promise.reject(new Error(response.msg || '获取失败!'))
          }
        } catch (error) {
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      disposeLeftVal(item) {
        let leftVal = Math.abs(Number(item.leftVal))
        if (Number(item.targetVal) < 0) {
          return '超额 ' + this.getThousandsValue(leftVal)
        }

        if (Number(item.completeProgress) >= 100) {
          return '超额 ' + this.getThousandsValue(leftVal)
        } else if (Number(item.completeProgress) < 100) {
          return this.getThousandsValue(leftVal)
        }
      },
      async handleStartCalendar(date) {
        this.startDateInfo.show = false
        this.dateType = 'custom'
        this.conditionCheckName = ''
        this.startDateInfo.date = date
        this.endDateInfo.minDate = date // 结束日期不能大于启始日期
        this.startDate = moment(date).format('YYYY-MM-DD')
        let start = new Date(this.startDate).getTime()
        let end = new Date(this.endDate).getTime()
        if (start > end) { // 开始时间大于结束时间时：结束时间清空
          this.endDate = ''
          return
        }
        if (this.showType === 3) {
          await this.getDetail()
        } else if (this.showType === 4) {
          await this.getAreaList()
        } else if (this.showType === 5) {
          await this.getAreaDetailList()
        } else {
          await this.getTableList()
        }
      },
      async handleEndCalendar(date) {
        this.endDateInfo.show = false
        this.dateType = 'custom'
        this.conditionCheckName = ''
        this.endDateInfo.date = date
        this.endDate = moment(date).format('YYYY-MM-DD')
        if (this.showType === 3) {
          await this.getDetail()
        } else if (this.showType === 4) {
          await this.getAreaList()
        } else if (this.showType === 5) {
          await this.getAreaDetailList()
        } else {
          await this.getTableList()
        }
      },
      async handleCondition(type) {
        this.conditionCheckName = type
        let weekOfDay = new Date().getDay() // 今天星期几
        switch (type) {
          case '昨日':
            this.startDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
            this.dateType = 'day'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '今日':
            this.startDate = moment(new Date()).format('YYYY-MM-DD')
            this.endDate = moment(new Date()).format('YYYY-MM-DD')
            this.dateType = 'day'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '上周':
            this.startDate = moment(new Date()).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
            this.dateType = 'week'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '本周':
            this.startDate = moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).endOf('isoWeek').format('YYYY-MM-DD')
            this.dateType = 'week'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '上月':
            this.startDate = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            this.dateType = 'month'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '本月':
            this.startDate = moment(new Date()).startOf('month').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).endOf('month').format('YYYY-MM-DD')
            this.dateType = 'month'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
        }
      },
      // 限制结束时间选择要大于开始时间、设置默认值
      async setDateRange() {
        this.endDateInfo.minDate = new Date(this.startDate) // 结束时间选择要大于开始时间
        this.startDateInfo.defaultDate = new Date(this.startDate)
        this.endDateInfo.defaultDate = new Date(this.endDate)
        if (this.showType === 3) {
          await this.getDetail()
        } else if (this.showType === 4) {
          await this.getAreaList()
        } else if (this.showType === 5) {
          await this.getAreaDetailList()
        } else {
          await this.getTableList()
        }
      },
      // 项目信息跳转
      handleProject(row) {
        this.projectId = row.projectId
        this.showType = 3
        this.getDetail()
      },
      // 片区列表跳转
      handleArea() {
        this.showType = 4
        // // 如果是url上的时间  就把片区视角的时间往前推一天
        // let date = moment(this.$route.query.date).format('YYYY-MM-DD')
        // this.startDate = moment(date).format('YYYY-MM-DD')
        // this.endDate = moment(date).format('YYYY-MM-DD')
        // if (date == this.startDate && date == this.endDate) {
        //   this.startDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD')
        //   this.endDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD')
        // }
        this.getAreaList()
      },
      // 片区列表明细跳转
      handleAreaProject(row) {
        this.projectId = row.projectId
        this.showType = 5
        this.getAreaDetailList()
      },
      async getDetail({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let params = {
            projectId: this.projectId,
            dateType: this.dateType,
            startDate: moment(this.startDate).format('YYYYMMDD'),
            endDate: moment(this.endDate).format('YYYYMMDD'),
            pmsUserId: this.pmsUserId
          }
          let res = this.statisticsType === '1'
            ? await getDetailList(params)
            : await getJsrDetailList(params)
          if (res.data.code === 200) {
            this.detailList = res.data.result
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: res.msg || '获取失败!'
            })
            return Promise.reject(new Error(res.msg || '获取失败!'))
          }
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getAreaList({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let params = {
            dateType: this.dateType,
            startDate: moment(this.startDate).format('YYYYMMDD'),
            endDate: moment(this.endDate).format('YYYYMMDD'),
            pmsUserId: this.pmsUserId
          }
          const res = this.statisticsType === '1'
            ? await getAreaChongCiList(params)
            : await getJsrAreaChongCiList(params)
          if (res.data.code === 200) {
            this.areaList = res.data.result
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: res.msg || '获取失败!'
            })
            return Promise.reject(new Error(res.msg || '获取失败!'))
          }
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getAreaDetailList({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let params = {
            dateType: this.dateType,
            projectId: this.projectId,
            startDate: moment(this.startDate).format('YYYYMMDD'),
            endDate: moment(this.endDate).format('YYYYMMDD'),
            pmsUserId: this.pmsUserId
          }
          const res = this.statisticsType === '1'
            ? await getAreaChongCiDetail(params)
            : await getJsrAreaChongCiDetail(params)
          if (res.data.code === 200) {
            this.areaDetailList = res.data.result
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: res.msg || '获取失败!'
            })
            return Promise.reject(new Error(res.msg || '获取失败!'))
          }
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      handleBack() {
        this.initData()
      },
      handleAreaBack() {
        this.showType = 4
        this.getAreaList()
      },
      warter_mark(str) {
        new WaterMark({
          watermark_rows: 20, // 水印行数
          watermark_txt: str, // 水印内容
          watermark_width: 120, // 水印宽度
          watermark_color: '', // 字体颜色
          watermark_fontsize: '16px', // 字体大小
          watermark_angle: 40, // 字体倾斜角度
          watermark_height: 140, // 水印长度
          watermark_y_space: 10, // Y轴间隔
          watermark_alpha: '0.1' // 水印字体透明度
        })
      }
    }
  }
</script>

<style scoped lang='less'>
  .container {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    overflow: auto;

    .select {
      height: 50px;
      padding: 0 12px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: bold;
      }

      .start_date, .end_date {
        width: 120px;
        height: 30px;
        padding: 0 5px;
        background-color: #F5F5F5;
        border-radius: 4px;
        display: flex;
        align-items: center;
      }
    }

    .Condition {
      padding: 12px;
      display: flex;

      .button-red {
        margin-right: 8px;

        /deep/ .van-button__text {
          color: red !important;
          //font-weight: bold;
        }

        /deep/ .van-button--small {
          height: 27px;
        }

        /deep/ .van-button--default {
          background-color: #fef0f0;
          color: #f56c6c;
          border: 0.02667rem solid #fde2e2;
        }
      }

      .button-green {
        margin-right: 8px;

        /deep/ .van-button__text {
          color: #67c23a !important;
          //font-weight: bold;
        }

        /deep/ .van-button--small {
          height: 27px;
        }

        /deep/ .van-button--default {
          background-color: #f0f9eb;
          color: #67c23a;
          border: 0.02667rem solid #e1f3d8;
        }
      }

    }

    /deep/ .van-tabs__nav {
      background-color: #F8F8F8;
    }

    /deep/ .van-tabs__line {
      background-color: #388BFE;
    }

    /deep/ .van-tab__text {
      font-weight: 600;
    }


    .content {
      //width: 100%;
      margin-top: 15px;
      background-color: #FFFFFF;
      overflow: auto;

      &-table {
        padding: 12px;
        width: 590px;

        .spec_table {
          width: 100%;

          &.table {
            border: solid #cccccc;
            border-spacing: 0;
            border-width: 1px 0px 0px 1px;
          }

          td,
          th {
            min-width: 100px;
            font-size: 10px;
            border: solid #cccccc;
            border-width: 0px 1px 1px 0px;
            padding: 12px 5px;
            text-align: center;
          }

          thead {
            background: #f5f5f5;

            th {
              &:first-child {
                min-width: 160px;
              }
            }
          }
        }
      }
    }

    .area {
      position: fixed;
      right: 0;
      bottom: 100px;
      width: 50px;
      height: 50px;
      color: #FFFFFF;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #5e89f5;
    }
  }

  tr:nth-last-child(1) {
    td {
      background-color: rgba(240, 192, 184, 0.2);
    }
  }

  .red {
    color: red;
  }

  .green {
    color: #07c160;
  }

  .blue {
    color: #5a75f3;
  }
</style>
